import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import '../../components/loader.css';
import Swal from 'sweetalert2'

var FormData = require('form-data');
const System_settings = (props) => {
   
    let navigate = useNavigate();
    const [paymentstate, setPaymentState] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [state, setstate] = useState({});
    console.log("state=====> ", state)
    const[states,setStates] = useState({state_id:""})
    const[city,setCity] = useState({city_id:""})
    const [stateLoad, setstateLoad] = useState({isLoading:true});
    const [addimg, setAddimg] = useState({ showLogo: "", uploadLogo: "", showIcon: "", uploadIcon: "" });
    const [socialstate, setState] = useState([]);
    const [smtpstate, setSmtpState] = useState({isLoading:true},[]);
    const [emailstate,setemailState] = useState([]);
const [otherStates,setOtherState] = useState({dstatus:1,searchKeyword:"",activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
const [extraStates,setExtraStates] = useState({isLoading:true});
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    useEffect(() => {
        getCompanyDetail();
        getPaymentListing()

    }, [])

    const getPaymentListing = () => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/payment_setting_api`;
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res payment ==== ", res);
            setPaymentState(res.data.output, { isLoading: false })

        }).catch((e) => {


        });
    }

    const paymentUpdate = (updateData) => {
        let mediaList = [...state];
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/payment_setting_update_api`;
        let sendData = updateData;
        console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res payment ", res);
            if (res.status == 200) {
                if (res.data.output.pid) {
                    let indexP = paymentstate.findIndex(item => item.pid == res.data.output.pid);
                    console.log("med ", mediaList[indexP]);
                    mediaList[indexP] = res.data.output;
                    setPaymentState(mediaList)
                }

                Swal.fire(
                    'Good job!',
                    'Updated successfully !',
                    'success'
                )

            }

        }).catch((e) => {


        });
    }
    const inputHandleChangepayment = (e, indexP) => {
        console.log("e ", e.target.name);
        console.log("e ", e.target.value);
        console.log("indedx ", indexP);
        let mediaList = [...paymentstate];
        // let indexP = state.findIndex(item => item.pid == e.target.name);
        console.log("indexP ", indexP);
        // if (e.target.name == "165432255560") {
        mediaList[indexP][e.target.name] = e.target.value;
        // } else {
        // mediaList[indexP].details = e.target.value;
        // }
        console.log("mediaList ", mediaList);
        setPaymentState(mediaList)

    }

    const handleChangepayment = (e, pid) => {
        console.log("e ", e.target.name);
        console.log("e ", e.target.checked);
        let mediaList = [...paymentstate];
        paymentUpdate({ pid: pid, dstatus: (e.target.checked) ? 1 : 0 })
        // let indexP = state.findIndex(item => item.pid == e.target.name);
        //   mediaList[indexP].dstatus = (e.target.checked) ? 1 : 0;
        //  setState(mediaList)
        // console.log("index ", indexP);

    }
    const updatePayment = () => {
        paymentUpdate(state)
    }

    const getCountryList = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/country_list`;
        let sendData = {limit:300};
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            // console.log("res ", res);           
            setcountryList(res.data.output)


        }).catch((e) => {


        });
    }

    const getStateList = (country_id) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/state_list`;
        let sendData = { t_country_id: country_id, limit: 1000 };
          console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
             console.log("state ", res);           
            setStateList(res.data.output)


        }).catch((e) => {


        });
    }


    const getCompanyDetail = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/company_info_api`;
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
              console.log("companyData ", res);
            if (res.data.companyDetail.length > 0) {
                let companyData = res.data.companyDetail[0];
                setAddimg({ ...addimg, showLogo: "https://shippxcdn.fniix.com/web/" + companyData.logo,showIcon:(companyData.favicon != "")?"https://shippxcdn.fniix.com/web/" + companyData.favicon :"" })
               
                setstate(companyData);
                setstateLoad({isLoading:false})
                //  getStateList(companyData.country)
                // getCityList(companyData.state)
            }



        }).catch((e) => {


        });
    }

    const companySettingUpdate = (UpdateData) => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/edit_company_api`;
        let sendData = { company_id: state.company_id, data: UpdateData };
        //console.log("sendData   ", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            //console.log("res ", res); 
            if(res.data.status=="success"){
                navigate('/system_settings')   
                getCompanyDetail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#206bc4",
                    type: 'success',
                    title: "Update Sucessfully",
                    color:"white"
                  });

            }       
           

        }).catch((e) => {

        });
    }

    const generalSettingUpdate = (e) => {

        //console.log("state  ", state);
        let generalInfo = { company_name: state.company_name, email: state.email, mob_number: state.mob_number, company_address: state.company_address, country: state.country, state: state.state, city: state.city, latitude: state.latitude, longitude: state.longitude }
        companySettingUpdate(generalInfo)

    }
    const System_setting_update = (e) => {
        let systemSetting = { google_map_key: state.google_map_key, time_format: state.time_format, date_format: state.date_format, set_weight: state.set_weight, set_distance: state.set_distance, set_dimension: state.set_dimension, set_currency: state.set_currency, set_time_zone: state.set_time_zone,chat_now:state.chat_now }
        console.log(systemSetting);
        companySettingUpdate(systemSetting)
        
    }

    const onChangeHandlerGeneral = (e) => {
        // console.log("e general name ",e.target.name);
          console.log("e general value ",e.target.type);
         const newdata = { ...state };
         if(e.target.name=="mob_number"){
             newdata[e.target.name] = e.target.value
         }
         newdata[e.target.name] = (e.target.type != "checkbox") ? e.target.value : e.target.checked;
         setstate(newdata);
     }

    // const onChangeHandlerSystem = (e) => {

    // }

    const onChangeLocationGeneral = (e) => {
        // console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
        const newdata = { ...state };
        newdata[e.target.name] = e.target.value;
        setstate(newdata);
        if (e.target.name == "country") {            
            getStateList(e.target.value)
            setStateList([]);
            setCityList([]);
        }
       
    }
    const onChangeLocationstate = (e) => {
        console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
         
         
       let values =   Object.assign({},e.target.value.split(',') )
       console.log("first",values[0])
       setStates({state_id:values[0]})
       setstate({ ...state,state:values[0]})

       getCityList({state_id:values[0], country_id:values[1]})
      
       
      }
      const onChangeLocationcity = (e) => {
        console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
         
       setCity({city_id:e.target.value})
       setstate({...state,city:e.target.value})

      
      
       
      }
      const getCityList = (value) => {
      
       console.log("value",value)
          let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/city_list`;
          let sendData = { state_id: value.state_id,country_id:value.country_id, limit: 1000 };
          console.log("bb", sendData);
          axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
              // console.log("city  ", res);           
              setCityList(res.data.output)
      
      
          }).catch((e) => {
      
      
          });
      }

    // const onImageSelect = (e) => {
    //     console.log("event  ", e.target.name);
    //     if(e.target.name == "logo") {
    //     setAddimg({ ...addimg, showLogo: URL.createObjectURL(e.target.files[0]), uploadLogo: e.target.files[0] })
    //     }else if(e.target.name == "favicon") {
    //         setAddimg({ ...addimg, showIcon: URL.createObjectURL(e.target.files[0]), uploadIcon: e.target.files[0] })
    //     }

    // }
    // const systemLogoUpdate = () => {
    //     if (addimg.uploadLogo != "" || addimg.uploadIcon != "") {
    //         let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/edit_company_logo_api`;
    //         let bodyFormData = new FormData()
    //         if(addimg.uploadLogo != "") {
    //             bodyFormData.append("fileImage", addimg.uploadLogo)
    //             bodyFormData.append("imageType", "logo");
    //         }else if(addimg.uploadIcon != "") {
    //             bodyFormData.append("fileImage", addimg.uploadIcon)
    //             bodyFormData.append("imageType", "favicon");
    //         }
    //         bodyFormData.append("company_id", state.company_id);

    //         // let sendData = {};
    //         console.log("bodyFormData ", bodyFormData);
    //         axios.post(full_api, bodyFormData, {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //                 // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //                 "Content-Type": "multipart/form-data",
    //                 // "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",

    //             }
    //         }).then((res) => {
    //             console.log("res ", res);

    //             navigate('/system_settings')   

    //         }).catch((e) => {

    //         });
    //     }
    // }
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    
        React.useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
    
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);
    
        return windowDimensions;
    }
    const Invoice_setting_update = (e) => {
        let invoiceSetting = { invoice_address :state.invoice_address,
        invoice_cin : state.invoice_cin,
        invoice_email : state.invoice_email,
        invoice_gst : state.invoice_gst,
        invoice_irn : state.invoice_irn,
        invoice_name : state.invoice_name,
        invoice_pan : state.invoice_pan,
        invoice_phone : state.invoice_phone}
        console.log(invoiceSetting);
        companySettingUpdate(invoiceSetting)
        
    }
    const Bank_setting_update = (e) => {
        let bankSetting = {  account_number : state.account_number,
        bank : state.bank,
        branch : state.branch,
        entity_name : state.entity_name,
        ifsc_code : state.ifsc_code}
        console.log(bankSetting);
        companySettingUpdate(bankSetting)
        
    }
   
    useEffect(() => {
        getSocialListing();


    }, [])

    const getSocialListing = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/social_media_list_api`;
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
             console.log("res ", res);           
            
             setState(res.data.media_list,{isLoading:false})

        }).catch((e) => {


        });
    }

    const socialMediaUpdate = (updateData) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/social_media_update_api`;
        let sendData = updateData;
         console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
             console.log("res ", res);           
            
            

        }).catch((e) => {


        });
    }

    const handleChange = (e) =>{
        console.log("e ", e.target.name);
        console.log("e ", e.target.checked);
        socialMediaUpdate({social_media_id:e.target.name,dstatus:(e.target.checked)? 1 : 0})
        let indexP = socialstate.findIndex(item => item.social_media_id == e.target.name);
        socialstate[indexP].dstatus = (e.target.checked)? 1 : 0;
        console.log("index ",indexP);
        
    }

const inputHandleChange = (e) => {
    console.log("e ", e.target.name);
    console.log("e ", e.target.value);
    let mediaList = [...socialstate];
    let indexP = socialstate.findIndex(item => item.social_media_id == e.target.name);
    console.log("indexP ", indexP);
    mediaList[indexP].media_url = e.target.value;
    console.log("mediaList ", mediaList);
        setState(mediaList)

}
const updateMedia = () =>{
    socialMediaUpdate(state)
}

useEffect(() => {
    getSmtpSetting();


}, [])

const getSmtpSetting = () => {
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/smtp_setting_api`;
    let sendData = {};
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        // console.log("res ", res);            
         setSmtpState(res.data.output[0],{isLoading:false})
    }).catch((e) => {


    });
}
const handleChangesmtp = (e) => {
    
    setSmtpState({...smtpstate,[e.target.name]:e.target.value})
}

const updateSmtp = (e) => {
    
   // console.log(state);
    let sendData = {sid:smtpstate.sid,data:smtpstate};
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/edit_smtp_configure_api`;
   
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        // console.log("res ", res);            
        // navigate('/smtp_settings')
    }).catch((e) => {


    });
}



useEffect(() => {
    getTemplateList();
},[])

useEffect(() => {
    getTemplateList();
},[otherStates.dstatus])

const getTemplateList = (searchKeyword) => {
    
    
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/admin_mail_list`;
    let sendData = {indexValue:otherStates.page,status:otherStates.dstatus};
    if(searchKeyword && searchKeyword != "") {
        sendData.searchText = searchKeyword;
    }
    console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res ", res);
        if(otherStates.onload){
            setOtherState({...otherStates,total_count:res.data.dataCount,onload:false})
        }
        setemailState(res.data.output)
        setExtraStates({isLoading:false})
       
        

    }).catch((e) => {


    });
}
   
    const { height, width } = useWindowDimensions();
    return (
//         <div>
//              <Sub_header />
//             <div style={{marginLeft:"15px",marginRight:"15px"}}>
              
//                   <section>
//                         <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
//                             <div class="d-block mb-4 mb-md-0">
//                                 <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
//                                     <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
//                                         <li class="breadcrumb-item">
//                                             <a href="#">
//                                                 <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
//                                             </a>
//                                         </li>
//                                         <li class="breadcrumb-item active" aria-current="page">Settings</li>
//                                     </ol>
//                                 </nav>
//                                 <h2 class="h4">Company Settings</h2>
//                             </div>
//                             <div class="btn-toolbar mb-2 mb-md-0">

//                             </div>
//                         </div>

//                     </section>

//                     <section id="generalinfo">
//                         <div class="row">
//                             <div class="col-12 col-xl-12">
//                                 <div class="card card-body border-0 shadow mb-4">
//                                     <h2 class="h5 mb-4">General information</h2>
//                                     <form>
//                                         <div class="row">
//                                             <div class="col-md-12 mb-3">
//                                                 <div>
//                                                     <label for="first_name">Company Name</label>
//                                                     <input class="form-control" type="text" placeholder="Enter your company name" required value={state.company_name} onChange={(e) => onChangeHandlerGeneral(e)} name="company_name" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="row">
//                                             <div class="col-md-6 mb-3">
//                                                 <div class="form-group">
//                                                     <label for="email">Email</label>
//                                                     <input class="form-control" id="email" type="email" placeholder="name@company.com" required value={state.email} onChange={(e) => onChangeHandlerGeneral(e)} name="email" />
//                                                 </div>
//                                             </div>
//                                             <div class="col-md-6 mb-3">
//                                                 <div class="form-group">
//                                                     <label for="phone">Phone</label>
//                                                     <input class="form-control" id="phone" type="number" placeholder="+12-345 678 910" required value={state.mob_number} onChange={(e) => onChangeHandlerGeneral(e)} name="mob_number" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <h2 class="h5 my-4">Location</h2>
//                                         <div class="row">
//                                             <div class="col-sm-12 mb-3">
//                                                 <div class="form-group">
//                                                     <label for="address">Address</label>
//                                                     <input class="form-control" id="address" type="text" placeholder="Enter your home address" required value={state.company_address} onChange={(e) => onChangeHandlerGeneral(e)} name="company_address" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="row">
//                                             <div class="col-sm-4 mb-3">
//                                                 <label for="state">Country</label>
//                                                 <select class="form-select w-100 mb-0" id="state" name="country" onFocus={()=>  getCountryList()} aria-label="State select example" onChange={(e) => onChangeLocationGeneral(e)}  >
//                                                     <option>Select</option>
//                                                     {countryList.map((item, index) => {
//                                                         return (
//                                                             <option value={item.country_id}>{item.country_name}</option>
//                                                         )
//                                                     })}
//                                                 </select >
//                                             </div>
//                                             <div class="col-sm-4 mb-3">
//                                                 <label for="state">State</label>
//                                                 <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example" onChange={(e) => onChangeLocationstate(e)} >
//                                                     <option >Select</option>
//                                                     {stateList.map((item, index) => {
//                                                         return (
//                                                             <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
//                                                         )
//                                                     })}
//                                                 </select>
//                                             </div>
//                                             <div class="col-sm-4 mb-3">
//                                                 <label for="state">City</label>
//                                                 <select class="form-select w-100 mb-0" id="state" name="city" aria-label="State select example" onChange={(e) => onChangeLocationcity(e)} >
//                                                     <option>Select</option>
//                                                     {cityList.map((item, index) => {
//                                                         return (
//                                                             <option value={item.city_id}>{item.city_name}</option>
//                                                         )
//                                                     })}
//                                                 </select>
//                                             </div>
                                           
//                                         </div>
//                                         <div class="mt-3">
//                                             <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={(e) => generalSettingUpdate(e)}>Save</button>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </section>


//                     <section id="system_info">
//                         <div class="row">
//                             <div class="col-12 col-xl-12">
//                                 <div class="card card-body border-0 shadow mb-4">
//                                     <h2 class="h5 mb-4">System Settings</h2>
//                                     <form >
//                                         <div class="row">
//                                             <div class="col-12 col-sm-6 mb-3">
//                                                 <label for="state">Default Time Zone</label>
//                                                 <select class="form-select w-100 mb-0" id="state" name="set_time_zone" aria-label="State select example" value={state.set_time_zone} onChange={(e) => onChangeHandlerGeneral(e)}>
//                                                     <option >Please Select </option>
                                                   
//                                                     <option value="Kwajalein">(GMT -12:00) Eniwetok, Kwajalein</option>
//                                             <option value="Pacific/Midway">(GMT -11:00) Midway Island, Samoa</option>
//                                             <option value="Pacific/Honolulu">(GMT -10:00) Hawaii</option>
//                                             <option value="America/Anchorage">(GMT -9:00) Alaska</option>
//                                             <option value="America/Los_Angeles">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
//                                             <option value="America/Denver">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
//                                             <option value="America/Tegucigalpa">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
//                                             <option value="America/New_York">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
//                                             <option value="America/Halifax">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
//                                             <option value="America/St_Johns">(GMT -3:30) Newfoundland</option>
//                                             <option value="America/Argentina/Buenos_Aires">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
//                                             <option value="Atlantic/South_Georgia">(GMT -2:00) Mid-Atlantic</option>
//                                             <option value="Atlantic/Azores">(GMT -1:00 hour) Azores, Cape Verde Islands</option>
//                                             <option value="Europe/Dublin">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
//                                             <option value="Europe/Belgrade">(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris</option>
//                                             <option value="Europe/Minsk">(GMT +2:00) Kaliningrad, South Africa</option>
//                                             <option value="Asia/Kuwait">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
//                                             <option value="Asia/Tehran">(GMT +3:30) Tehran</option>
//                                             <option value="Asia/Muscat">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
//                                             <option value="Asia/Kabul">(GMT +4:30) Kabul</option>
//                                             <option value="Asia/Yekaterinburg">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
//                                             <option value="Asia/Kolkata" >(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
//                                             <option value="Asia/Dhaka">(GMT +6:00) Almaty, Dhaka, Colombo</option>
//                                             <option value="Asia/Krasnoyarsk">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
//                                             <option value="Asia/Brunei">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
//                                             <option value="Asia/Seoul">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
//                                             <option value="Australia/Darwin">(GMT +9:30) Adelaide, Darwin</option>
//                                             <option value="Australia/Canberra">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
//                                             <option value="Asia/Magadan">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
//                                             <option value="Pacific/Fiji">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
//                                             <option value="Pacific/Fiji">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
//                                             <option value="Pacific/Fiji">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
//                                                 </select>
//                                             </div>
//                                             <div class="col-12 col-sm-6 mb-3">
//                                                 <label for="state">Default Currency Code</label>
//                                                 <select class="form-select w-100 mb-0" id="state" name="set_currency" aria-label="State select example" value={state.set_currency} onChange={(e) => onChangeHandlerGeneral(e)}>
//                                                     <option >Please Select </option>
                                                   
//                                                     <option value="USD" label="US dollar">USD</option>
//                                                 <option value="EUR" label="Euro">EUR</option>
//                                                 <option value="JPY" label="Japanese yen">JPY</option>
//                                                 <option value="GBP" label="Pound sterling">GBP</option>
//                                                 <option value="AED" label="United Arab Emirates dirham">AED</option>
//                                                 <option value="AFN" label="Afghan afghani">AFN</option>
//                                                 <option value="ALL" label="Albanian lek">ALL</option>
//                                                 <option value="AMD" label="Armenian dram">AMD</option>
//                                                 <option value="ANG" label="Netherlands Antillean guilder">ANG</option>
//                                                 <option value="AOA" label="Angolan kwanza">AOA</option>
//                                                 <option value="ARS" label="Argentine peso">ARS</option>
//                                                 <option value="AUD" label="Australian dollar">AUD</option>
//                                                 <option value="AWG" label="Aruban florin">AWG</option>
//                                                 <option value="AZN" label="Azerbaijani manat">AZN</option>
//                                                 <option value="BAM" label="Bosnia and Herzegovina convertible mark">BAM</option>
//                                                 <option value="BBD" label="Barbadian dollar">BBD</option>
//                                                 <option value="BDT" label="Bangladeshi taka">BDT</option>
//                                                 <option value="BGN" label="Bulgarian lev">BGN</option>
//                                                 <option value="BHD" label="Bahraini dinar">BHD</option>
//                                                 <option value="BIF" label="Burundian franc">BIF</option>
//                                                 <option value="BMD" label="Bermudian dollar">BMD</option>
//                                                 <option value="BND" label="Brunei dollar">BND</option>
//                                                 <option value="BOB" label="Bolivian boliviano">BOB</option>
//                                                 <option value="BRL" label="Brazilian real">BRL</option>
//                                                 <option value="BSD" label="Bahamian dollar">BSD</option>
//                                                 <option value="BTN" label="Bhutanese ngultrum">BTN</option>
//                                                 <option value="BWP" label="Botswana pula">BWP</option>
//                                                 <option value="BYN" label="Belarusian ruble">BYN</option>
//                                                 <option value="BZD" label="Belize dollar">BZD</option>
//                                                 <option value="CAD" label="Canadian dollar">CAD</option>
//                                                 <option value="CDF" label="Congolese franc">CDF</option>
//                                                 <option value="CHF" label="Swiss franc">CHF</option>
//                                                 <option value="CLP" label="Chilean peso">CLP</option>
//                                                 <option value="CNY" label="Chinese yuan">CNY</option>
//                                                 <option value="COP" label="Colombian peso">COP</option>
//                                                 <option value="CRC" label="Costa Rican colón">CRC</option>
//                                                 <option value="CUC" label="Cuban convertible peso">CUC</option>
//                                                 <option value="CUP" label="Cuban peso">CUP</option>
//                                                 <option value="CVE" label="Cape Verdean escudo">CVE</option>
//                                                 <option value="CZK" label="Czech koruna">CZK</option>
//                                                 <option value="DJF" label="Djiboutian franc">DJF</option>
//                                                 <option value="DKK" label="Danish krone">DKK</option>
//                                                 <option value="DOP" label="Dominican peso">DOP</option>
//                                                 <option value="DZD" label="Algerian dinar">DZD</option>
//                                                 <option value="EGP" label="Egyptian pound">EGP</option>
//                                                 <option value="ERN" label="Eritrean nakfa">ERN</option>
//                                                 <option value="ETB" label="Ethiopian birr">ETB</option>
//                                                 <option value="EUR" label="EURO">EUR</option>
//                                                 <option value="FJD" label="Fijian dollar">FJD</option>
//                                                 <option value="FKP" label="Falkland Islands pound">FKP</option>
//                                                 <option value="GBP" label="British pound">GBP</option>
//                                                 <option value="GEL" label="Georgian lari">GEL</option>
//                                                 <option value="GGP" label="Guernsey pound">GGP</option>
//                                                 <option value="GHS" label="Ghanaian cedi">GHS</option>
//                                                 <option value="GIP" label="Gibraltar pound">GIP</option>
//                                                 <option value="GMD" label="Gambian dalasi">GMD</option>
//                                                 <option value="GNF" label="Guinean franc">GNF</option>
//                                                 <option value="GTQ" label="Guatemalan quetzal">GTQ</option>
//                                                 <option value="GYD" label="Guyanese dollar">GYD</option>
//                                                 <option value="HKD" label="Hong Kong dollar">HKD</option>
//                                                 <option value="HNL" label="Honduran lempira">HNL</option>
//                                                 <option value="HKD" label="Hong Kong dollar">HKD</option>
//                                                 <option value="HRK" label="Croatian kuna">HRK</option>
//                                                 <option value="HTG" label="Haitian gourde">HTG</option>
//                                                 <option value="HUF" label="Hungarian forint">HUF</option>
//                                                 <option value="IDR" label="Indonesian rupiah">IDR</option>
//                                                 <option value="ILS" label="Israeli new shekel">ILS</option>
//                                                 <option value="IMP" label="Manx pound">IMP</option>
//                                                 <option value="INR" label="Indian rupee">INR</option>
//                                                 <option value="IQD" label="Iraqi dinar">IQD</option>
//                                                 <option value="IRR" label="Iranian rial">IRR</option>
//                                                 <option value="ISK" label="Icelandic króna">ISK</option>
//                                                 <option value="JEP" label="Jersey pound">JEP</option>
//                                                 <option value="JMD" label="Jamaican dollar">JMD</option>
//                                                 <option value="JOD" label="Jordanian dinar">JOD</option>
//                                                 <option value="JPY" label="Japanese yen">JPY</option>
//                                                 <option value="KES" label="Kenyan shilling">KES</option>
//                                                 <option value="KGS" label="Kyrgyzstani som">KGS</option>
//                                                 <option value="KHR" label="Cambodian riel">KHR</option>
//                                                 <option value="KID" label="Kiribati dollar">KID</option>
//                                                 <option value="KMF" label="Comorian franc">KMF</option>
//                                                 <option value="KPW" label="North Korean won">KPW</option>
//                                                 <option value="KRW" label="South Korean won">KRW</option>
//                                                 <option value="KWD" label="Kuwaiti dinar">KWD</option>
//                                                 <option value="KYD" label="Cayman Islands dollar">KYD</option>
//                                                 <option value="KZT" label="Kazakhstani tenge">KZT</option>
//                                                 <option value="LAK" label="Lao kip">LAK</option>
//                                                 <option value="LBP" label="Lebanese pound">LBP</option>
//                                                 <option value="LKR" label="Sri Lankan rupee">LKR</option>
//                                                 <option value="LRD" label="Liberian dollar">LRD</option>
//                                                 <option value="LSL" label="Lesotho loti">LSL</option>
//                                                 <option value="LYD" label="Libyan dinar">LYD</option>
//                                                 <option value="MAD" label="Moroccan dirham">MAD</option>
//                                                 <option value="MDL" label="Moldovan leu">MDL</option>
//                                                 <option value="MGA" label="Malagasy ariary">MGA</option>
//                                                 <option value="MKD" label="Macedonian denar">MKD</option>
//                                                 <option value="MMK" label="Burmese kyat">MMK</option>
//                                                 <option value="MNT" label="Mongolian tögrög">MNT</option>
//                                                 <option value="MOP" label="Macanese pataca">MOP</option>
//                                                 <option value="MRU" label="Mauritanian ouguiya">MRU</option>
//                                                 <option value="MUR" label="Mauritian rupee">MUR</option>
//                                                 <option value="MVR" label="Maldivian rufiyaa">MVR</option>
//                                                 <option value="MWK" label="Malawian kwacha">MWK</option>
//                                                 <option value="MXN" label="Mexican peso">MXN</option>
//                                                 <option value="MYR" label="Malaysian ringgit">MYR</option>
//                                                 <option value="MZN" label="Mozambican metical">MZN</option>
//                                                 <option value="NAD" label="Namibian dollar">NAD</option>
//                                                 <option value="NGN" label="Nigerian naira">NGN</option>
//                                                 <option value="NIO" label="Nicaraguan córdoba">NIO</option>
//                                                 <option value="NOK" label="Norwegian krone">NOK</option>
//                                                 <option value="NPR" label="Nepalese rupee">NPR</option>
//                                                 <option value="NZD" label="New Zealand dollar">NZD</option>
//                                                 <option value="OMR" label="Omani rial">OMR</option>
//                                                 <option value="PAB" label="Panamanian balboa">PAB</option>
//                                                 <option value="PEN" label="Peruvian sol">PEN</option>
//                                                 <option value="PGK" label="Papua New Guinean kina">PGK</option>
//                                                 <option value="PHP" label="Philippine peso">PHP</option>
//                                                 <option value="PKR" label="Pakistani rupee">PKR</option>
//                                                 <option value="PLN" label="Polish złoty">PLN</option>
//                                                 <option value="PRB" label="Transnistrian ruble">PRB</option>
//                                                 <option value="PYG" label="Paraguayan guaraní">PYG</option>
//                                                 <option value="QAR" label="Qatari riyal">QAR</option>
//                                                 <option value="RON" label="Romanian leu">RON</option>
//                                                 <option value="RON" label="Romanian leu">RON</option>
//                                                 <option value="RSD" label="Serbian dinar">RSD</option>
//                                                 <option value="RUB" label="Russian ruble">RUB</option>
//                                                 <option value="RWF" label="Rwandan franc">RWF</option>
//                                                 <option value="SAR" label="Saudi riyal">SAR</option>
//                                                 <option value="SEK" label="Swedish krona">SEK</option>
//                                                 <option value="SGD" label="Singapore dollar">SGD</option>
//                                                 <option value="SHP" label="Saint Helena pound">SHP</option>
//                                                 <option value="SLL" label="Sierra Leonean leone">SLL</option>
//                                                 <option value="SLS" label="Somaliland shilling">SLS</option>
//                                                 <option value="SOS" label="Somali shilling">SOS</option>
//                                                 <option value="SRD" label="Surinamese dollar">SRD</option>
//                                                 <option value="SSP" label="South Sudanese pound">SSP</option>
//                                                 <option value="STN" label="São Tomé and Príncipe dobra">STN</option>
//                                                 <option value="SYP" label="Syrian pound">SYP</option>
//                                                 <option value="SZL" label="Swazi lilangeni">SZL</option>
//                                                 <option value="THB" label="Thai baht">THB</option>
//                                                 <option value="TJS" label="Tajikistani somoni">TJS</option>
//                                                 <option value="TMT" label="Turkmenistan manat">TMT</option>
//                                                 <option value="TND" label="Tunisian dinar">TND</option>
//                                                 <option value="TOP" label="Tongan paʻanga">TOP</option>
//                                                 <option value="TRY" label="Turkish lira">TRY</option>
//                                                 <option value="TTD" label="Trinidad and Tobago dollar">TTD</option>
//                                                 <option value="TVD" label="Tuvaluan dollar">TVD</option>
//                                                 <option value="TWD" label="New Taiwan dollar">TWD</option>
//                                                 <option value="TZS" label="Tanzanian shilling">TZS</option>
//                                                 <option value="UAH" label="Ukrainian hryvnia">UAH</option>
//                                                 <option value="UGX" label="Ugandan shilling">UGX</option>
//                                                 <option value="USD" label="United States dollar">USD</option>
//                                                 <option value="UYU" label="Uruguayan peso">UYU</option>
//                                                 <option value="UZS" label="Uzbekistani soʻm">UZS</option>
//                                                 <option value="VES" label="Venezuelan bolívar soberano">VES</option>
//                                                 <option value="VND" label="Vietnamese đồng">VND</option>
//                                                 <option value="VUV" label="Vanuatu vatu">VUV</option>
//                                                 <option value="WST" label="Samoan tālā">WST</option>
//                                                 <option value="XAF" label="Central African CFA franc">XAF</option>
//                                                 <option value="XCD" label="Eastern Caribbean dollar">XCD</option>
//                                                 <option value="XOF" label="West African CFA franc">XOF</option>
//                                                 <option value="XPF" label="CFP franc">XPF</option>
//                                                 <option value="ZAR" label="South African rand">ZAR</option>
//                                                 <option value="ZMW" label="Zambian kwacha">ZMW</option>
//                                                 <option value="ZWB" label="Zimbabwean bonds">ZWB</option>
//                                                 </select>
//                                             </div>
//                                             <div class="col-12 col-sm-6 mb-3">
//                                                 <label for="state">Default Dimension</label>
//                                                 <select class="form-select w-100 mb-0" id="state" name="set_dimension" aria-label="State select example" value={state.set_dimension} onChange={(e) => onChangeHandlerGeneral(e)}>
//                                                     <option >Please Select </option>
//                                                     <option value="M">M</option>
//                                                     <option value="CM">CM</option>
//                                                 </select>
//                                             </div>
//                                             <div class="col-12 col-sm-6 mb-3">
//                                                 <label for="state">Default Distance Code</label>
//                                                 <select class="form-select w-100 mb-0" id="state" name="set_distance" aria-label="State select example" value={state.set_distance} onChange={(e) => onChangeHandlerGeneral(e)}>
//                                                     <option >Please Select </option>
                                                    
//                                                     <option value="KM" >KM</option>
//                                             <option value="MILES" >MILES</option>
//                                             <option value="CM" >CM</option>
//                                                 </select>
//                                             </div>
//                                             <div class="col-12 col-sm-6 mb-3">
//                                                 <label for="state">Default Weight Code</label>
//                                                 <select class="form-select w-100 mb-0" id="state" name="set_weight" aria-label="State select example" value={state.set_weight} onChange={(e) => onChangeHandlerGeneral(e)}>
//                                                     <option >Please Select </option>
//                                                     <option value="KG">KG</option>
//                                                     <option value="LBS">LBS</option>
//                                                 </select>
//                                             </div>
//                                             <div class="col-12 col-sm-6 mb-3">
//                                                 <div class="form-group">
//                                                     <label for="city">Date Format</label>
//                                                     <select class="form-select w-100 mb-0" id="state" name="date_format" aria-label="State select example" value={state.date_format} onChange={(e) => onChangeHandlerGeneral(e)}>
//                                                     <option >Please Select </option>
//                                                         <option value="DD/MM/YYYY">DD/MM/YYYY</option>
//                                                         <option value="YYYY/MM/DD">YYYY/MM/DD</option>
                                                        
                                                      
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div class="col-12 col-sm-6 mb-3">
//                                                 <div class="form-group">
//                                                     <label for="city">Time Format</label>
//                                                     <select class="form-select w-100 mb-0" id="state" name="time_format" aria-label="State select example" value={state.time_format} onChange={(e) => onChangeHandlerGeneral(e)}>
//                                                     <option >Please Select </option>
//                                                         <option value="12">12 Hours </option>
//                                                         <option value="24">24 Hours</option>
//                                                     </select>
//                                                 </div>
//                                             </div>
                                          
//                                         </div>
//                                         <div class="mt-3">
//                                             <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={(e) => System_setting_update(e)}>Save</button>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </section>
//  </div>
                            
//             </div>
                     <div>
                        {/* <?php
            include('header.php');
        ?> */}

       <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?>  */}
<Sub_header/>

        <div class="row mt-3 g-1">
            <div class="col-12 col-md-3 ">
                <ul class="nav flex-column nav-tabs system_settings ">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#home"><span><i class="fa fa-building" aria-hidden="true"></i></span>Company Settings</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " data-bs-toggle="tab" href="#menu1"><span><i class="fa fa-credit-card" aria-hidden="true"></i></span>Payment Gateway Settings</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " data-bs-toggle="tab" href="#menu2"><span><i class="fa fa-bullhorn" aria-hidden="true"></i></span>Social Media Settings</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " data-bs-toggle="tab" href="#menu3"><span><i class="fa fa-envelope" aria-hidden="true"></i></span>SMTP Email Settings</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#menu4"><span><i class="fa fa-envelope-open" aria-hidden="true"></i></span>Email Templates Settings</a>
                    </li>
                    </ul>
            </div>
            <div class="col-12 col-md">
                <div class="tab-content">
                    <div class="tab-pane container active" id="home">
                    <section id="generalinfo">
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <div class="card card-body border-0 shadow mb-4">
                                    <h2 class="h5 mb-4">General information</h2>
                                    <form>
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <div>
                                                    <label for="first_name">Company Name</label>
                                                    <input class="form-control" type="text" placeholder="Enter your company name" required value={state.company_name} onChange={(e) => onChangeHandlerGeneral(e)} name="company_name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <div class="form-group">
                                                    <label for="email">Email</label>
                                                    <input class="form-control" id="email" type="email" placeholder="name@company.com" required value={state.email} onChange={(e) => onChangeHandlerGeneral(e)} name="email" />
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <div class="form-group">
                                                    <label for="phone">Phone</label>
                                                    <input class="form-control" id="phone" type="number" placeholder="+12-345 678 910" required value={state.mob_number} onChange={(e) => onChangeHandlerGeneral(e)} name="mob_number" />
                                                </div>
                                            </div>
                                        </div>
                                        <h2 class="h5 my-4">Location</h2>
                                        <div class="row">
                                            <div class="col-sm-12 mb-3">
                                                <div class="form-group">
                                                    <label for="address">Address</label>
                                                    <input class="form-control" id="address" type="text" placeholder="Enter your home address" required value={state.company_address} onChange={(e) => onChangeHandlerGeneral(e)} name="company_address" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4 mb-3">
                                                <label for="state">Country</label>
                                                <select class="form-select w-100 mb-0" id="state" name="country" onFocus={()=>  getCountryList()} aria-label="State select example" onChange={(e) => onChangeLocationGeneral(e)}  >
                                                    <option>Select</option>
                                                    {countryList.map((item, index) => {
                                                        return (
                                                            <option value={item.country_id}>{item.country_name}</option>
                                                        )
                                                    })}
                                                </select >
                                            </div>
                                            <div class="col-sm-4 mb-3">
                                                <label for="state">State</label>
                                                <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example" onChange={(e) => onChangeLocationstate(e)} >
                                                    <option >Select</option>
                                                    {stateList.map((item, index) => {
                                                        return (
                                                            <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div class="col-sm-4 mb-3">
                                                <label for="state">City</label>
                                                <select class="form-select w-100 mb-0" id="state" name="city" aria-label="State select example" onChange={(e) => onChangeLocationcity(e)} >
                                                    <option>Select</option>
                                                    {cityList.map((item, index) => {
                                                        return (
                                                            <option value={item.city_id}>{item.city_name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {/* <div class="col-sm-12 mb-3">
                                                <div class="form-group">
                                                    <label for="address">Google Map Location</label>
                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text">Lat/Long</span>
                                                        <input type="text" class="form-control" placeholder="Lat" value={state.latitude} onChange={(e) => onChangeHandlerGeneral(e)} name="latitude" />
                                                        <input type="text" class="form-control" placeholder="Long" value={state.longitude} onChange={(e) => onChangeHandlerGeneral(e)} name="longitude" />
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div class="mt-3">
                                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={(e) => generalSettingUpdate(e)}>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section id="system_info">
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <div class="card card-body border-0 shadow mb-4">
                                    <h2 class="h5 mb-4">System Settings</h2>
                                    <form >
                                        <div class="row">
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="state">Default Time Zone</label>
                                                <select class="form-select w-100 mb-0" id="state" name="set_time_zone" aria-label="State select example" value={state.set_time_zone} onChange={(e) => onChangeHandlerGeneral(e)}>
                                                    <option >Please Select </option>
                                                   
                                                    <option value="Kwajalein">(GMT -12:00) Eniwetok, Kwajalein</option>
                                            <option value="Pacific/Midway">(GMT -11:00) Midway Island, Samoa</option>
                                            <option value="Pacific/Honolulu">(GMT -10:00) Hawaii</option>
                                            <option value="America/Anchorage">(GMT -9:00) Alaska</option>
                                            <option value="America/Los_Angeles">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                            <option value="America/Denver">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                            <option value="America/Tegucigalpa">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                            <option value="America/New_York">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                            <option value="America/Halifax">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                            <option value="America/St_Johns">(GMT -3:30) Newfoundland</option>
                                            <option value="America/Argentina/Buenos_Aires">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                            <option value="Atlantic/South_Georgia">(GMT -2:00) Mid-Atlantic</option>
                                            <option value="Atlantic/Azores">(GMT -1:00 hour) Azores, Cape Verde Islands</option>
                                            <option value="Europe/Dublin">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                            <option value="Europe/Belgrade">(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris</option>
                                            <option value="Europe/Minsk">(GMT +2:00) Kaliningrad, South Africa</option>
                                            <option value="Asia/Kuwait">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                            <option value="Asia/Tehran">(GMT +3:30) Tehran</option>
                                            <option value="Asia/Muscat">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                            <option value="Asia/Kabul">(GMT +4:30) Kabul</option>
                                            <option value="Asia/Yekaterinburg">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                            <option value="Asia/Kolkata" >(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                            <option value="Asia/Dhaka">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                            <option value="Asia/Krasnoyarsk">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                            <option value="Asia/Brunei">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                            <option value="Asia/Seoul">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                            <option value="Australia/Darwin">(GMT +9:30) Adelaide, Darwin</option>
                                            <option value="Australia/Canberra">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                            <option value="Asia/Magadan">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                            <option value="Pacific/Fiji">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                            <option value="Pacific/Fiji">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                            <option value="Pacific/Fiji">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="state">Default Currency Code</label>
                                                <select class="form-select w-100 mb-0" id="state" name="set_currency" aria-label="State select example" value={state.set_currency} onChange={(e) => onChangeHandlerGeneral(e)}>
                                                    <option >Please Select </option>
                                                   
                                                    <option value="USD" label="US dollar">USD</option>
                                                <option value="EUR" label="Euro">EUR</option>
                                                <option value="JPY" label="Japanese yen">JPY</option>
                                                <option value="GBP" label="Pound sterling">GBP</option>
                                                <option value="AED" label="United Arab Emirates dirham">AED</option>
                                                <option value="AFN" label="Afghan afghani">AFN</option>
                                                <option value="ALL" label="Albanian lek">ALL</option>
                                                <option value="AMD" label="Armenian dram">AMD</option>
                                                <option value="ANG" label="Netherlands Antillean guilder">ANG</option>
                                                <option value="AOA" label="Angolan kwanza">AOA</option>
                                                <option value="ARS" label="Argentine peso">ARS</option>
                                                <option value="AUD" label="Australian dollar">AUD</option>
                                                <option value="AWG" label="Aruban florin">AWG</option>
                                                <option value="AZN" label="Azerbaijani manat">AZN</option>
                                                <option value="BAM" label="Bosnia and Herzegovina convertible mark">BAM</option>
                                                <option value="BBD" label="Barbadian dollar">BBD</option>
                                                <option value="BDT" label="Bangladeshi taka">BDT</option>
                                                <option value="BGN" label="Bulgarian lev">BGN</option>
                                                <option value="BHD" label="Bahraini dinar">BHD</option>
                                                <option value="BIF" label="Burundian franc">BIF</option>
                                                <option value="BMD" label="Bermudian dollar">BMD</option>
                                                <option value="BND" label="Brunei dollar">BND</option>
                                                <option value="BOB" label="Bolivian boliviano">BOB</option>
                                                <option value="BRL" label="Brazilian real">BRL</option>
                                                <option value="BSD" label="Bahamian dollar">BSD</option>
                                                <option value="BTN" label="Bhutanese ngultrum">BTN</option>
                                                <option value="BWP" label="Botswana pula">BWP</option>
                                                <option value="BYN" label="Belarusian ruble">BYN</option>
                                                <option value="BZD" label="Belize dollar">BZD</option>
                                                <option value="CAD" label="Canadian dollar">CAD</option>
                                                <option value="CDF" label="Congolese franc">CDF</option>
                                                <option value="CHF" label="Swiss franc">CHF</option>
                                                <option value="CLP" label="Chilean peso">CLP</option>
                                                <option value="CNY" label="Chinese yuan">CNY</option>
                                                <option value="COP" label="Colombian peso">COP</option>
                                                <option value="CRC" label="Costa Rican colón">CRC</option>
                                                <option value="CUC" label="Cuban convertible peso">CUC</option>
                                                <option value="CUP" label="Cuban peso">CUP</option>
                                                <option value="CVE" label="Cape Verdean escudo">CVE</option>
                                                <option value="CZK" label="Czech koruna">CZK</option>
                                                <option value="DJF" label="Djiboutian franc">DJF</option>
                                                <option value="DKK" label="Danish krone">DKK</option>
                                                <option value="DOP" label="Dominican peso">DOP</option>
                                                <option value="DZD" label="Algerian dinar">DZD</option>
                                                <option value="EGP" label="Egyptian pound">EGP</option>
                                                <option value="ERN" label="Eritrean nakfa">ERN</option>
                                                <option value="ETB" label="Ethiopian birr">ETB</option>
                                                <option value="EUR" label="EURO">EUR</option>
                                                <option value="FJD" label="Fijian dollar">FJD</option>
                                                <option value="FKP" label="Falkland Islands pound">FKP</option>
                                                <option value="GBP" label="British pound">GBP</option>
                                                <option value="GEL" label="Georgian lari">GEL</option>
                                                <option value="GGP" label="Guernsey pound">GGP</option>
                                                <option value="GHS" label="Ghanaian cedi">GHS</option>
                                                <option value="GIP" label="Gibraltar pound">GIP</option>
                                                <option value="GMD" label="Gambian dalasi">GMD</option>
                                                <option value="GNF" label="Guinean franc">GNF</option>
                                                <option value="GTQ" label="Guatemalan quetzal">GTQ</option>
                                                <option value="GYD" label="Guyanese dollar">GYD</option>
                                                <option value="HKD" label="Hong Kong dollar">HKD</option>
                                                <option value="HNL" label="Honduran lempira">HNL</option>
                                                <option value="HKD" label="Hong Kong dollar">HKD</option>
                                                <option value="HRK" label="Croatian kuna">HRK</option>
                                                <option value="HTG" label="Haitian gourde">HTG</option>
                                                <option value="HUF" label="Hungarian forint">HUF</option>
                                                <option value="IDR" label="Indonesian rupiah">IDR</option>
                                                <option value="ILS" label="Israeli new shekel">ILS</option>
                                                <option value="IMP" label="Manx pound">IMP</option>
                                                <option value="INR" label="Indian rupee">INR</option>
                                                <option value="IQD" label="Iraqi dinar">IQD</option>
                                                <option value="IRR" label="Iranian rial">IRR</option>
                                                <option value="ISK" label="Icelandic króna">ISK</option>
                                                <option value="JEP" label="Jersey pound">JEP</option>
                                                <option value="JMD" label="Jamaican dollar">JMD</option>
                                                <option value="JOD" label="Jordanian dinar">JOD</option>
                                                <option value="JPY" label="Japanese yen">JPY</option>
                                                <option value="KES" label="Kenyan shilling">KES</option>
                                                <option value="KGS" label="Kyrgyzstani som">KGS</option>
                                                <option value="KHR" label="Cambodian riel">KHR</option>
                                                <option value="KID" label="Kiribati dollar">KID</option>
                                                <option value="KMF" label="Comorian franc">KMF</option>
                                                <option value="KPW" label="North Korean won">KPW</option>
                                                <option value="KRW" label="South Korean won">KRW</option>
                                                <option value="KWD" label="Kuwaiti dinar">KWD</option>
                                                <option value="KYD" label="Cayman Islands dollar">KYD</option>
                                                <option value="KZT" label="Kazakhstani tenge">KZT</option>
                                                <option value="LAK" label="Lao kip">LAK</option>
                                                <option value="LBP" label="Lebanese pound">LBP</option>
                                                <option value="LKR" label="Sri Lankan rupee">LKR</option>
                                                <option value="LRD" label="Liberian dollar">LRD</option>
                                                <option value="LSL" label="Lesotho loti">LSL</option>
                                                <option value="LYD" label="Libyan dinar">LYD</option>
                                                <option value="MAD" label="Moroccan dirham">MAD</option>
                                                <option value="MDL" label="Moldovan leu">MDL</option>
                                                <option value="MGA" label="Malagasy ariary">MGA</option>
                                                <option value="MKD" label="Macedonian denar">MKD</option>
                                                <option value="MMK" label="Burmese kyat">MMK</option>
                                                <option value="MNT" label="Mongolian tögrög">MNT</option>
                                                <option value="MOP" label="Macanese pataca">MOP</option>
                                                <option value="MRU" label="Mauritanian ouguiya">MRU</option>
                                                <option value="MUR" label="Mauritian rupee">MUR</option>
                                                <option value="MVR" label="Maldivian rufiyaa">MVR</option>
                                                <option value="MWK" label="Malawian kwacha">MWK</option>
                                                <option value="MXN" label="Mexican peso">MXN</option>
                                                <option value="MYR" label="Malaysian ringgit">MYR</option>
                                                <option value="MZN" label="Mozambican metical">MZN</option>
                                                <option value="NAD" label="Namibian dollar">NAD</option>
                                                <option value="NGN" label="Nigerian naira">NGN</option>
                                                <option value="NIO" label="Nicaraguan córdoba">NIO</option>
                                                <option value="NOK" label="Norwegian krone">NOK</option>
                                                <option value="NPR" label="Nepalese rupee">NPR</option>
                                                <option value="NZD" label="New Zealand dollar">NZD</option>
                                                <option value="OMR" label="Omani rial">OMR</option>
                                                <option value="PAB" label="Panamanian balboa">PAB</option>
                                                <option value="PEN" label="Peruvian sol">PEN</option>
                                                <option value="PGK" label="Papua New Guinean kina">PGK</option>
                                                <option value="PHP" label="Philippine peso">PHP</option>
                                                <option value="PKR" label="Pakistani rupee">PKR</option>
                                                <option value="PLN" label="Polish złoty">PLN</option>
                                                <option value="PRB" label="Transnistrian ruble">PRB</option>
                                                <option value="PYG" label="Paraguayan guaraní">PYG</option>
                                                <option value="QAR" label="Qatari riyal">QAR</option>
                                                <option value="RON" label="Romanian leu">RON</option>
                                                <option value="RON" label="Romanian leu">RON</option>
                                                <option value="RSD" label="Serbian dinar">RSD</option>
                                                <option value="RUB" label="Russian ruble">RUB</option>
                                                <option value="RWF" label="Rwandan franc">RWF</option>
                                                <option value="SAR" label="Saudi riyal">SAR</option>
                                                <option value="SEK" label="Swedish krona">SEK</option>
                                                <option value="SGD" label="Singapore dollar">SGD</option>
                                                <option value="SHP" label="Saint Helena pound">SHP</option>
                                                <option value="SLL" label="Sierra Leonean leone">SLL</option>
                                                <option value="SLS" label="Somaliland shilling">SLS</option>
                                                <option value="SOS" label="Somali shilling">SOS</option>
                                                <option value="SRD" label="Surinamese dollar">SRD</option>
                                                <option value="SSP" label="South Sudanese pound">SSP</option>
                                                <option value="STN" label="São Tomé and Príncipe dobra">STN</option>
                                                <option value="SYP" label="Syrian pound">SYP</option>
                                                <option value="SZL" label="Swazi lilangeni">SZL</option>
                                                <option value="THB" label="Thai baht">THB</option>
                                                <option value="TJS" label="Tajikistani somoni">TJS</option>
                                                <option value="TMT" label="Turkmenistan manat">TMT</option>
                                                <option value="TND" label="Tunisian dinar">TND</option>
                                                <option value="TOP" label="Tongan paʻanga">TOP</option>
                                                <option value="TRY" label="Turkish lira">TRY</option>
                                                <option value="TTD" label="Trinidad and Tobago dollar">TTD</option>
                                                <option value="TVD" label="Tuvaluan dollar">TVD</option>
                                                <option value="TWD" label="New Taiwan dollar">TWD</option>
                                                <option value="TZS" label="Tanzanian shilling">TZS</option>
                                                <option value="UAH" label="Ukrainian hryvnia">UAH</option>
                                                <option value="UGX" label="Ugandan shilling">UGX</option>
                                                <option value="USD" label="United States dollar">USD</option>
                                                <option value="UYU" label="Uruguayan peso">UYU</option>
                                                <option value="UZS" label="Uzbekistani soʻm">UZS</option>
                                                <option value="VES" label="Venezuelan bolívar soberano">VES</option>
                                                <option value="VND" label="Vietnamese đồng">VND</option>
                                                <option value="VUV" label="Vanuatu vatu">VUV</option>
                                                <option value="WST" label="Samoan tālā">WST</option>
                                                <option value="XAF" label="Central African CFA franc">XAF</option>
                                                <option value="XCD" label="Eastern Caribbean dollar">XCD</option>
                                                <option value="XOF" label="West African CFA franc">XOF</option>
                                                <option value="XPF" label="CFP franc">XPF</option>
                                                <option value="ZAR" label="South African rand">ZAR</option>
                                                <option value="ZMW" label="Zambian kwacha">ZMW</option>
                                                <option value="ZWB" label="Zimbabwean bonds">ZWB</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="state">Default Dimension</label>
                                                <select class="form-select w-100 mb-0" id="state" name="set_dimension" aria-label="State select example" value={state.set_dimension} onChange={(e) => onChangeHandlerGeneral(e)}>
                                                    <option >Please Select </option>
                                                    <option value="M">M</option>
                                                    <option value="CM">CM</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="state">Default Distance Code</label>
                                                <select class="form-select w-100 mb-0" id="state" name="set_distance" aria-label="State select example" value={state.set_distance} onChange={(e) => onChangeHandlerGeneral(e)}>
                                                    <option >Please Select </option>
                                                    
                                                    <option value="KM" >KM</option>
                                            <option value="MILES" >MILES</option>
                                            <option value="CM" >CM</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="state">Default Weight Code</label>
                                                <select class="form-select w-100 mb-0" id="state" name="set_weight" aria-label="State select example" value={state.set_weight} onChange={(e) => onChangeHandlerGeneral(e)}>
                                                    <option >Please Select </option>
                                                    <option value="KG">KG</option>
                                                    <option value="LBS">LBS</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <div class="form-group">
                                                    <label for="city">Date Format</label>
                                                    <select class="form-select w-100 mb-0" id="state" name="date_format" aria-label="State select example" value={state.date_format} onChange={(e) => onChangeHandlerGeneral(e)}>
                                                    <option >Please Select </option>
                                                        <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                                                        <option value="YYYY/MM/DD">YYYY/MM/DD</option>
                                                        
                                                      
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <div class="form-group">
                                                    <label for="city">Time Format</label>
                                                    <select class="form-select w-100 mb-0" id="state" name="time_format" aria-label="State select example" value={state.time_format} onChange={(e) => onChangeHandlerGeneral(e)}>
                                                    <option >Please Select </option>
                                                        <option value="12">12 Hours </option>
                                                        <option value="24">24 Hours</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div class="col-12 col-sm-6 mb-3">
                                            <div class="form-group">
                                            <label for="city">Chat now</label>
                                                    </div>
                                            <div class="form-check form-switch">
                                                   
                                                    <input class="form-check-input" type="checkbox"  name="chat_now" defaultChecked={state.chat_now} onChange={(e)=>onChangeHandlerGeneral(e)} />
                                                    <label class="form-check-label" for="mySwitch"></label>
                                                </div>
                                            </div> */}
                                            {/* <div class="col-12 col-sm-12 mb-3">
                                                <div class="form-group">
                                                    <label for="city">Google Map Key</label>
                                                    <input class="form-control" id="city" type="text" placeholder="Google Map Key" name="google_map_key"  value={state.google_map_key} onChange={(e) => onChangeHandlerGeneral(e)}/>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div class="mt-3">
                                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={(e) => System_setting_update(e)}>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="system_info">
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <div class="card card-body border-0 shadow mb-4">
                                    <h2 class="h5 mb-4">Invoice Settings</h2>
                                    <form >
                                        <div class="row">
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="name">Name</label>
                                                <input class="form-control" id="invoice_name" type="text" placeholder="Enter your Company Name" required value={state.invoice_name} onChange={(e) => onChangeHandlerGeneral(e)} name="invoice_name" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="state">Address</label>
                                                <input class="form-control" id="invoice_address" type="text" placeholder="Enter your Company Address" required value={state.invoice_address} onChange={(e) => onChangeHandlerGeneral(e)} name="invoice_address" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                            <label for="state">Email</label>
                                                <input class="form-control" id="invoice_email" type="text" placeholder="Enter your Company Email" required value={state.invoice_email} onChange={(e) => onChangeHandlerGeneral(e)} name="invoice_email" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                            <label for="state">Phone</label>
                                                <input class="form-control" id="invoice_phone" type="text" placeholder="Enter your Company Phone" required value={state.invoice_phone} onChange={(e) => onChangeHandlerGeneral(e)} name="invoice_phone" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                            <label for="state">PAN Number</label>
                                                <input class="form-control" id="invoice_pan" type="text" placeholder="Enter your Company PAN Number" required value={state.invoice_pan} onChange={(e) => onChangeHandlerGeneral(e)} name="invoice_pan" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <div class="form-group">
                                                <label for="state">CIN Number</label>
                                                <input class="form-control" id="invoice_cin" type="text" placeholder="Enter your Company CIN Number" required value={state.invoice_cin} onChange={(e) => onChangeHandlerGeneral(e)} name="invoice_cin" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <div class="form-group">
                                                <label for="state">GST Number</label>
                                                <input class="form-control" id="invoice_gst" type="text" placeholder="Enter your Company GST Number" required value={state.invoice_gst} onChange={(e) => onChangeHandlerGeneral(e)} name="invoice_gst" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <div class="form-group">
                                                <label for="state">IRN Number</label>
                                                <input class="form-control" id="invoice_irn" type="text" placeholder="Enter your Company IRN Number" required value={state.invoice_irn} onChange={(e) => onChangeHandlerGeneral(e)} name="invoice_irn" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={(e) => Invoice_setting_update(e)}>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="system_info">
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <div class="card card-body border-0 shadow mb-4">
                                    <h2 class="h5 mb-4">Bank Details Settings</h2>
                                    <form >
                                        <div class="row">
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="name">Entity Name</label>
                                                <input class="form-control" id="entity_name" type="text" placeholder="Enter Entity Name" required value={state.entity_name} onChange={(e) => onChangeHandlerGeneral(e)} name="entity_name" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                                <label for="state">Account Number</label>
                                                <input class="form-control" id="account_number" type="text" placeholder="Enter Account Number" required value={state.account_number} onChange={(e) => onChangeHandlerGeneral(e)} name="account_number" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                            <label for="state">Bank</label>
                                                <input class="form-control" id="bank" type="text" placeholder="Enter Bank Name" required value={state.bank} onChange={(e) => onChangeHandlerGeneral(e)} name="bank" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                            <label for="state">Branch</label>
                                                <input class="form-control" id="branch" type="text" placeholder="Enter Branch Name" required value={state.branch} onChange={(e) => onChangeHandlerGeneral(e)} name="branch" />
                                            </div>
                                            <div class="col-12 col-sm-6 mb-3">
                                            <label for="state">IFSC Code</label>
                                                <input class="form-control" id="ifsc_code" type="text" placeholder="Enter IFSC Code" required value={state.ifsc_code} onChange={(e) => onChangeHandlerGeneral(e)} name="ifsc_code" />
                                            </div>
                                           
                                          
                                        </div>
                                        <div class="mt-3">
                                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={(e) => Bank_setting_update(e)}>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    </div>
                    <div class="tab-pane container fade " id="menu1">
                        <section id="payment_gateway_info">
                            <div class="row">
                                <div class="col-12 col-xl-12 payment_gateway_info_box">
                                    <div class="card card-body border-0 shadow mb-4">
                                       {paymentstate.map((sub)=>(
                                        <div class="accordion" id="payment_accordion">
                                            <div class="accordion-item">
                                              <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                  <img src={sub.name=="Razorpay"?"./assets/img/razorpay.png":sub.name=="Phonepay"?"./assets/img/phonepay.png":sub.name=="Paypal"?"./assets/img/paypal.png":""} class="img-fluid" />
                                                 {sub.mode==2? <span class="badge bg-success ms-3">Live Mode</span>:sub.mode==1?<span class="badge bg-success ms-3">Sandbox Mode</span>:sub.dstatus==0?<span class="badge bg-success ms-3">Off</span>:""}
                                                </button>
                                              </h2>
                                              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#payment_accordion">
                                                <div class="accordion-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-2">
                                                            <label for="email">Active Mode:</label>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <select class="form-select form-select-sm">
                                                                <option>Live</option>
                                                                <option>Sandbox</option>
                                                                <option>Off</option>
                                                              </select>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-12 col-md-6">
                                                            <div class="card">
                                                                <div class="card-header">
                                                                    Live Mode
                                                                </div>
                                                              <div class="card-body">
                                                              {sub.name=="Paypal"?"":
                                                                <div class="mb-3">
                                                                    <label for="email">Gateway ID</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Merchant_email" value={sub.live_gateway_id} />
                                                                </div>}

                                                                <div class="mb-3">
                                                                    <label for="email">Key</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Client_id" value={sub.live_key} />
                                                                </div>
                                                                {sub.name=="Paypal"?"":
                                                                <div class="mb-3">
                                                                    <label for="email">Url</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Secrect_key" value={sub.live_url} />
                                                                </div>
}
                                                              </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="card">
                                                                <div class="card-header">
                                                                    Sandbox Mode
                                                                </div>
                                                              <div class="card-body">
                                                                {sub.name=="Paypal"?"":
                                                                <div class="mb-3">
                                                                    <label for="email">Gateway ID</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Merchant_email" value={sub.demo_gateway_id} />
                                                                </div>
                                                                }
                                                                <div class="mb-3">
                                                                    <label for="email">Key</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Client_id" value={sub.live_key}/>
                                                                </div>
                                                                {sub.name=="Paypal"?"":
                                                                <div class="mb-3">
                                                                    <label for="email">Url</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Secrect_key" value={sub.live_url}/>
                                                                </div>
                                                                }
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div class="accordion-item">
                                              <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <img src="./assets/img/phonepay.png" class="img-fluid" />
                                                    <span class="badge bg-secondary ms-3">Sandbox Mode</span>
                                                </button>
                                              </h2>
                                              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#payment_accordion">
                                                <div class="accordion-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-2">
                                                            <label for="email">Active Mode:</label>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <select class="form-select form-select-sm">
                                                                <option>Live</option>
                                                                <option>Sandbox</option>
                                                                <option>Off</option>
                                                              </select>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-12 col-md-6">
                                                            <div class="card">
                                                                <div class="card-header">
                                                                    Live Mode
                                                                </div>
                                                              <div class="card-body">
                                                                <div class="mb-3">
                                                                    <label for="email">Merchant_email</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Merchant_email" />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="email">Client_id</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Client_id" />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="email">Secrect_key</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Secrect_key" />
                                                                </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="card">
                                                                <div class="card-header">
                                                                    Sandbox Mode
                                                                </div>
                                                              <div class="card-body">
                                                                <div class="mb-3">
                                                                    <label for="email">Merchant_email</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Merchant_email" />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="email">Client_id</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Client_id" />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="email">Secrect_key</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Secrect_key" />
                                                                </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="accordion-item">
                                              <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <img src="./assets/img/paypal.png" class="img-fluid" />
                                                    <span class="badge bg-danger ms-3">Off</span>
                                                </button>
                                              </h2>
                                              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#payment_accordion">
                                                <div class="accordion-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-2">
                                                            <label for="email">Active Mode:</label>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <select class="form-select form-select-sm">
                                                                <option>Live</option>
                                                                <option>Sandbox</option>
                                                                <option>Off</option>
                                                              </select>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-12 col-md-6">
                                                            <div class="card">
                                                                <div class="card-header">
                                                                    Live Mode
                                                                </div>
                                                              <div class="card-body">
                                                                <div class="mb-3">
                                                                    <label for="email">Merchant_email</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Merchant_email"   />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="email">Client_id</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Client_id" />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="email">Secrect_key</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Secrect_key" />
                                                                </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="card">
                                                                <div class="card-header">
                                                                    Sandbox Mode
                                                                </div>
                                                              <div class="card-body">
                                                                <div class="mb-3">
                                                                    <label for="email">Merchant_email</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Merchant_email" />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="email">Client_id</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Client_id" />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label for="email">Secrect_key</label>
                                                                    <input class="form-control" id="first_name" type="text" placeholder="Enter your Secrect_key" />
                                                                </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </div> */}
                                          </div>
                                          ))}



                                          <div class="mt-3">
                                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="tab-pane container fade" id="menu2">
                        <section id="social_info">
                            <div class="row">
                                <div class="col-12 col-xl-12">
                                    <div class="card card-body border-0 shadow mb-4">

                                        <div class="row">
                                            <div class="col-12 col-md-6 mb-3">
                                                <div class="row">
                                                {socialstate.map((item,index)=>
                                
                                <div class="col-md-6 mb-3">
                                 <label for="">
                                     {item.media_name}
                                    </label>
                                    <div>
                                        <input class="form-control"  type="text" placeholder="Please Enter" 
                                         name={item.social_media_id} 
                                        onChange={(e)=>inputHandleChange(e)} 
                                         value={item.media_url}
                                        />
                                    </div>
                                    <div class="mt-2">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" 
                                              name={item.social_media_id} defaultChecked={item.dstatus} 
                                             onChange={(e)=>handleChange(e)} />
                                            <label class="form-check-label" for="mySwitch"></label>
                                        </div>
                                    </div>
                                </div>
                                    
                                 )} 
                                                   
                                                </div>
                                            </div>
                                            
                                          
                                          
                                          
                                        </div>

                                        <form>
                                            
                                            <div class="mt-3">
                                                <button class="btn btn-gray-800 mt-2 animate-up-2" type="button"  onClick={updateMedia}>Save</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="tab-pane container fade" id="menu3">
                        <section>
                            <div class="row">
                                <div class="col-12 col-xl-12">
                                    <div class="card card-body border-0 shadow mb-4">
                                    <form >
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="email">Port</label>
                                    <input class="form-control"  type="text" placeholder="i.e. 587" required onChange={(e)=>handleChangesmtp(e)} 
                                     value={smtpstate.port}
                                     name="port"/>
                                    <div class="text-danger small">(Sets SMTP Port. Default Port is 25)</div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="email">Host</label>
                                    <input class="form-control" id="email" type="text" placeholder="hostname" required onChange={(e)=>handleChangesmtp(e)} 
                                     value={smtpstate.host} 
                                    name="host"/>
                                    <div class="text-danger small">(SMTP server)</div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="email">Secure</label>
                                    <select class="form-select" onChange={(e)=>handleChangesmtp(e)}
                                      value={smtpstate.secure}
                                      name="secure">
                                        <option value="">Please Select</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="email">Username/email</label>
                                    <input class="form-control" id="email" type="text" placeholder="email@domain.com" required onChange={(e)=>handleChangesmtp(e)} 
                                     value={smtpstate.user_name}
                                     name="user_name"/>
                                    <div class="text-danger small">(SMTP username)</div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="phone">Password</label>
                                    <input class="form-control" id="phone" type="text" placeholder="*******" required onChange={(e)=>handleChangesmtp(e)} 
                                     value={smtpstate.password} 
                                    name="password"/>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="phone">Sender ID</label>
                                    <input class="form-control" id="phone" type="text" placeholder="Your sender ID" required onChange={(e)=>handleChangesmtp(e)} 
                                     value={smtpstate.sender_email}
                                     name="sender_email"/>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={(e)=>updateSmtp(e)}>Save </button>
                        </div>
                    </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                  
                     <div class="tab-pane container fade" id="menu4">
                        <section>
                            <h5>Global Template</h5>
                            <div class="card card-body border-0 shadow table-wrapper table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th class="border-gray-200">Name</th>
                                            <th class="border-gray-200">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <!-- Item --> */}
                                        <tr>                       
                                            <td><span class="fw-normal">Global Email Template</span></td>
                                            <td>
                                                <a href="/edit_global_email_template" class="btn btn-primary d-inline-flex align-items-center">
                                                    Edit  &nbsp; <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </a>
                                            </td>
                                        </tr>
                                                                    
                                    </tbody>
                                </table>
                                
                            </div> 
                        </section>
                        <section class="mt-4 email_template">
                            <h5>All Templates</h5>
                            <div class="card card-body border-0 shadow table-wrapper table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th class="border-gray-200">Name</th>						
                                            <th class="border-gray-200">Subject</th>
                                            <th class="border-gray-200">Status</th>
                                            <th class="border-gray-200">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {emailstate.map((item,index)=>
                                        <tr>                       
                                            <td><span class="fw-normal">  {item.name}</span></td>
                                            <td><span class="fw-bold">  {item.title}</span></td>
                                            <td>{(item.dstatus == 1)?
                                    <span class="badge rounded-pill bg-success">Active</span>:<span class="badge rounded-pill bg-danger">Inactive</span>
                                     }</td>
                                            <td>
                                                <a href={"/edit_email_template/"+item.mail_id} class="btn btn-primary d-inline-flex align-items-center">
                                                    Edit  &nbsp; <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    )}
                                                                    
                                    </tbody>
                                </table>
                                
                            </div> 
                        </section>
                    </div>
                </div>
            </div>
        </div>
             
                    

    </section>



                     </div>                           
        
    )
}

export default System_settings